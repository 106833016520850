


















































































































































































































































































































































































































































































































































































































































































































































































































































import DatePicker from "@/components/atom/DatePicker.vue";
import { YesNoSwitch } from "@/components/atom/switch";
import { VForm } from "@/models";
import router from "@/router";
import { ROUTE_NAMES } from "@/router/routes";
import {
  accountingPeriodsStore,
  coreStore,
  productsStore,
  realEstateAssetsStore,
  tasksStore,
  documentsStore,
  subscriptionsStore,
} from "@/store";
import {
  debounce,
  PROPERTY_STATUS,
  PROPERTY_TYPES,
  TAX_DEDUCTIONS,
} from "@/utils";
import {
  DPE,
  NewRealEstateAsset,
  RealEstateAsset,
  RealEstateAssetUpdate,
  AnomalyCode,
  DpeInformation,
  SubscriptionsModel,
} from "@edmp/api";
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { format } from "date-fns";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import Anomalies from "../anomalies/Anomalies.vue";
import { useRentalUnit } from "./rentalUnit.usable";
import SectionTitle from "../../atom/SectionTitle.vue";
import i18n from "@/plugins/i18n";

export default defineComponent({
  name: "RealEstateForm",
  components: {
    SectionTitle,
    YesNoSwitch,
    Anomalies,
    DatePicker,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    realEstateAssetId: {
      type: String,
      required: false,
      default: null,
    },
    isEditable: {
      type: Boolean,
      required: true,
    },
    smallDisplay: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getDpePicture(dpe: DPE) {
      if (dpe !== DPE.UNKNOWN) {
        return require(`@/assets/dpe/${dpe}.svg`);
      }
    },
  },

  setup(props, context) {
    const {
      initRentalUnit,
      updateOrCreateRentalUnit,
      prepareRentalUnitForCreation,
      rentalUnit,
      rentalStateList,
      rentalUsageList,
      rentalUsageLmnpList,
      taxTvaRule,
      taxRateTvaList,
      TaxRateTva,
      tvaRentalUsageMapping,
      taxTvaEnableMapping,
      anomalyOptions,
      TypeReference,
    } = useRentalUnit();
    const defaultRealEstate: NewRealEstateAsset = {
      productId: props.productId,
      name: "",
      address: {
        street: "",
        city: "",
        zip: "",
      },
      boughtAt: "",
      rentalUnitCount: 0,
      taxDeduction: TAX_DEDUCTIONS[0].value,
      nature: PROPERTY_TYPES[0].value,
      built: PROPERTY_STATUS[0].value,
      dpe: { dpeGrade: DPE.UNKNOWN },
      asbestos: { asbestosStatus: "Non contrôlé" },
    };

    const dpeLists = Object.values(DPE).filter(
      (dpe) => dpe !== DPE.UNKNOWN
    ) as DPE[];

    //In case of "à renseigner", complete realEstateAsset with defaultRealEstate
    const realEstateAsset = ref<NewRealEstateAsset | RealEstateAsset>(
      defaultRealEstate
    );

    // BoughtPrice
    const formattedBoughtPrice = computed(() =>
      realEstateAsset.value.boughtPrice
        ? i18n.n(realEstateAsset.value.boughtPrice, "currency", "fr-FR")
        : ""
    );
    const updateBoughtPrice = (value) => {
      realEstateAsset.value.boughtPrice = parseFloat(value) || 0;
    };

    const searchItemsStreetList = ref([]);
    const searchItemsCityList = ref([]);
    const searchItemsZipList = ref([]);

    const searchAddress = debounce((value: string, type: string) => {
      if (value.length > 2) {
        if (type === "street") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=housenumber`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                if (
                  feature.properties &&
                  feature.properties.name &&
                  feature.properties.postcode &&
                  feature.properties.city
                )
                  return {
                    text: `${feature.properties.name} ${feature.properties.postcode} - ${feature.properties.city}`,
                    value: feature.properties,
                  };
              });
              searchItemsStreetList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "city") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.city} - ${feature.properties.postcode}`,
                  value: feature.properties,
                };
              });
              searchItemsCityList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        } else if (type === "zip") {
          fetch(
            `https://api-adresse.data.gouv.fr/search/?q=${value}&limit=15&type=municipality`
          )
            .then((response) => response.json())
            .then((data) => {
              const mappedData = data.features.map((feature) => {
                return {
                  text: `${feature.properties.postcode} - ${feature.properties.city}`,
                  value: feature.properties,
                };
              });
              searchItemsZipList.value = mappedData.filter(
                (mappedData) => !!mappedData
              );
            });
        }
      }
    }, 500);

    const updateAddress = (event, field) => {
      if (event.type === "keyup") {
        realEstateAsset.value.address[field] = event.target.value;
      } else {
        if (field === "street") {
          realEstateAsset.value.address.street = event.value.name;
          realEstateAsset.value.address.city = event.value.city;
          realEstateAsset.value.address.zip = event.value.postcode;
        } else if (field === "city") {
          realEstateAsset.value.address.city = event.value.city;
          realEstateAsset.value.address.zip = event.value.postcode;
        } else if (field === "zip") {
          realEstateAsset.value.address.city = event.value.city;
          realEstateAsset.value.address.zip = event.value.postcode;
        }
      }
      adjustTaxRate();
    };

    const initialRentalUsage = () => {
      if (accountingPeriodsStore.isLMNP) {
        const rentalUsage = rentalUsageLmnpList.find(
          (el) => el.value === rentalUnit.value.rentalUsage
        );

        if (rentalUsage) {
          return rentalUsage;
        } else {
          return rentalUsageLmnpList[0];
        }
      } else {
        const rentalUsage = rentalUsageList.find(
          (el) => el.value === rentalUnit.value.rentalUsage
        );

        if (rentalUsage) {
          return rentalUsage;
        } else {
          return rentalUsageList[0];
        }
      }
    };

    const rentalUsage = ref({
      ...initialRentalUsage(),
    });

    watch(rentalUsage, () => {
      console.log(rentalUsage.value);
    });

    const validateInProgress: Ref<boolean> = ref(false);
    const deleteFileInProgress: Ref<boolean> = ref(false);
    const today = ref(format(new Date(), "yyyy-MM-dd"));

    const accountingPeriodId = accountingPeriodsStore.currentId;
    const modalTitle = computed(
      () => `${props.realEstateAssetId ? "Editer" : "Ajouter"} un bien`
    );
    const subscription = computed(() => {
      return subscriptionsStore.getCurrentSubscription;
    });

    // _____________ IMAGE, DPE & ASBESTOS ___________
    // Image/asbestos file input
    const fileImage: Ref<File | null> = ref(null);
    const fileDpe: Ref<File | null> = ref(null);
    const fileAsbestos: Ref<File | null> = ref(null);

    // Image/Asbestos/DPE form document Store
    const imageRealEstateAsset = computed(() => {
      if (realEstateAsset.value.documentId) {
        return documentsStore.getDocument(realEstateAsset.value.documentId);
      }
    });
    const asbestosDeclaration = computed(() => {
      if (realEstateAsset.value.asbestos?.documentAsbestosId) {
        return documentsStore.getDocument(
          realEstateAsset.value.asbestos?.documentAsbestosId
        );
      }
    });
    const dpeDiagnostic = computed(() => {
      if (realEstateAsset.value.dpe.documentDpeId) {
        return documentsStore.getDocument(
          realEstateAsset.value.dpe.documentDpeId
        );
      }
    });

    const statusAsbestosList = [
      { text: "Présent" },
      { text: "Absent" },
      { text: "Non contrôlé" },
    ];

    // We have to make 2 rules because you can't tell which is the image and which is the asbestos declaration.
    const realEstateAssetDocumentsRules = (params: {
      fileImage?: File | null;
      fileDpe?: File | null;
      fileAsbestos?: File | null;
    }): {
      valid: boolean;
      name: string;
      message: string;
    } => {
      if (params.fileImage) {
        if (
          params.fileImage.type !== "image/png" &&
          params.fileImage.type !== "image/jpeg"
        ) {
          return {
            valid: false,
            name: "TYPE ERROR",
            message:
              "Format de fichier non supporté. Seuls les formats JPEG et PNG sont acceptés.",
          };
        }

        if (params.fileImage?.size >= 3000000) {
          return {
            valid: false,
            name: "SIZE ERROR",
            message: `Le fichier dépasse la taille maximale autorisée de 3 Mo. Veuillez choisir un fichier plus petit.`,
          };
        }

        return { valid: true, name: "OK", message: "Aucune erreur" };
      }
      if (params.fileDpe) {
        if (
          params.fileDpe.type !== "image/png" &&
          params.fileDpe.type !== "application/msword" &&
          params.fileDpe.type !== "application/pdf" &&
          params.fileDpe.type !==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
          params.fileDpe.type !== "application/msword" &&
          params.fileDpe.type !== "application/vnd.oasis.opendocument.text" &&
          params.fileDpe.type !== "text/plain"
        ) {
          return {
            valid: false,
            name: "TYPE ERROR",
            message:
              "Format de fichier non supporté. Seuls les formats PDF, DOC, DOCX, ODT et TXT sont acceptés.",
          };
        }
        if (params.fileDpe?.size >= 20000000) {
          return {
            valid: false,
            name: "SIZE ERROR",
            message: `Le fichier dépasse la taille maximale autorisée de 5 Mo. Veuillez choisir un fichier plus petit.`,
          };
        }
      }
      if (params.fileAsbestos) {
        if (
          params.fileAsbestos.type !== "image/png" &&
          params.fileAsbestos.type !== "image/jpeg" &&
          params.fileAsbestos.type !== "application/pdf"
        ) {
          return {
            valid: false,
            name: "TYPE ERROR",
            message:
              "Format de fichier non supporté. Seuls les formats PDF, JPEG et PNG sont acceptés.",
          };
        }
        if (params.fileAsbestos?.size >= 20000000) {
          return {
            valid: false,
            name: "SIZE ERROR",
            message: `Le fichier dépasse la taille maximale autorisée de 20 Mo. Veuillez choisir un fichier plus petit.`,
          };
        }
      }

      return { valid: true, name: "OK", message: "Aucune erreur" };
    };
    // Remove image or a absbestos declaration
    const deleteDocuments = async (params: {
      asbestos?: Object | undefined;
      documentId?: string | undefined;
      dpe: DpeInformation;
    }) => {
      deleteFileInProgress.value = true;
      try {
        if (props.realEstateAssetId) {
          await realEstateAssetsStore.deleteDocument({
            ...params,
            id: props.realEstateAssetId,
          });
        }

        if (params.asbestos) {
          fileAsbestos.value = null;
          delete realEstateAsset.value.asbestos?.documentAsbestosId;
        } else if (params.documentId) {
          fileImage.value = null;
          delete realEstateAsset.value.documentId;
        } else if (params.dpe) {
          fileDpe.value = null;
          realEstateAsset.value = {
            ...realEstateAsset.value,
            dpe: { ...realEstateAsset.value.dpe, documentDpeId: undefined },
          };
        }
      } catch (error) {
        console.error(error);
      } finally {
        deleteFileInProgress.value = false;
      }
    };
    const isCrlEnableEditable: Ref<boolean> = ref(true);
    const onEditClick = () => {
      context.emit("setEditing", true);
    };
    async function validate() {
      validateInProgress.value = true;
      adjustMarketPrice();

      const files = {};

      if (fileImage.value !== null) {
        files["image"] = fileImage;
      }
      if (fileAsbestos.value !== null) {
        files["asbestos"] = fileAsbestos;
      }

      if (fileDpe.value !== null) {
        files["dpe"] = fileDpe;
      }
      if ((context.refs.form as VForm).validate()) {
        let realEstateToSave: NewRealEstateAsset | RealEstateAssetUpdate = {
          ...realEstateAsset.value,
        };
        if (realEstateAsset.value.dpe.dpeGrade === DPE.UNKNOWN) {
          realEstateToSave = {
            ...realEstateToSave,
            dpe: { dpeGrade: DPE.UNKNOWN },
          };
          if (realEstateAsset.value.dpe.documentDpeId) {
            await deleteDocuments({
              dpe: realEstateAsset.value.dpe,
            });
          }
        }
        if (realEstateAsset.value.asbestos?.asbestosStatus === "Non contrôlé") {
          realEstateToSave = {
            ...realEstateToSave,
            asbestos: { asbestosStatus: "Non contrôlé" },
          };
          if (realEstateAsset.value.asbestos.documentAsbestosId) {
            await deleteDocuments({
              asbestos: realEstateAsset.value.asbestos,
              dpe: realEstateAsset.value.dpe,
            });
          }
        }
        try {
          if (
            props.realEstateAssetId === null ||
            props.realEstateAssetId === "new"
          ) {
            const newRentalUnit = prepareRentalUnitForCreation({
              realEstateAsset: realEstateToSave,
              rentalUsage: rentalUsage.value?.value,
            });

            const { realEstateAsset } =
              await realEstateAssetsStore.createRealEstateAssetAndRentalUnits({
                realEstateAssetCreate: realEstateToSave as NewRealEstateAsset,
                rentalUnitCreate: newRentalUnit,
              });

            if (Object.keys(files).length >= 1) {
              if (realEstateAsset.asbestos !== undefined) {
                await realEstateAssetsStore.addDocuments({
                  id: realEstateAsset.id,
                  productId: props.productId,
                  asbestos: realEstateAsset.asbestos,
                  dpe: realEstateAsset.dpe,
                  accountingPeriodId,
                  files: files,
                });
              } else {
                await realEstateAssetsStore.addDocuments({
                  id: realEstateAsset.id,
                  productId: props.productId,
                  dpe: realEstateAsset.dpe,
                  accountingPeriodId,
                  files: files,
                });
              }
            }

            coreStore.displayFeedback({
              message: "La création de votre bien a bien été prise en compte",
            });
            context.emit("validate", realEstateAsset.id);
          } else {
            await realEstateAssetsStore.updateRealEstateAsset(
              realEstateToSave as RealEstateAssetUpdate
            );
            await updateOrCreateRentalUnit({
              realEstateAsset: realEstateToSave,
              rentalUsage: rentalUsage.value?.value,
            });

            if (Object.keys(files).length >= 1) {
              if (realEstateToSave.dpe !== undefined) {
                if (realEstateToSave.asbestos !== undefined) {
                  await realEstateAssetsStore.addDocuments({
                    id: props.realEstateAssetId,
                    productId: props.productId,
                    asbestos: realEstateToSave.asbestos,
                    dpe: realEstateToSave.dpe,
                    accountingPeriodId,

                    files: files,
                  });
                } else {
                  await realEstateAssetsStore.addDocuments({
                    id: props.realEstateAssetId,
                    productId: props.productId,
                    dpe: realEstateToSave.dpe,
                    accountingPeriodId,

                    files: files,
                  });
                }
              }
            }

            coreStore.displayFeedback({
              message:
                "La mise à jour de votre bien a bien été prise en compte",
            });
            context.emit("validate", props.realEstateAssetId);
          }
          context.emit("setEditing", false);
          fileImage.value = null;
          fileDpe.value = null;
          fileAsbestos.value = null;
          await tasksStore.fetchTaskGroups({});
        } catch (error) {
          console.error(error);
        }
      }
      validateInProgress.value = false;
    }
    async function navigateToDoc() {
      await productsStore.switchProduct({ id: props.productId });
      router.push({
        name: ROUTE_NAMES.Documents,
      });
    }
    function adjustMarketPrice() {
      if (
        !realEstateAsset.value.marketPrice &&
        compareBoughtAndEntryActivity("anterior")
      )
        realEstateAsset.value.marketPrice = realEstateAsset.value.boughtPrice;
      else if (!compareBoughtAndEntryActivity("anterior"))
        delete realEstateAsset.value.marketPrice;
    }
    const cancelEdition = async () => {
      await init();
      context.emit("setEditing", false);
      context.emit("cancel");
    };
    const compareBoughtAndEntryActivity = (
      requirement: "same" | "anterior" | "same_year"
    ) => {
      if (!realEstateAsset.value.entryDateActivityLmnp) return false;
      const diff = dayjs(realEstateAsset.value.boughtAt).diff(
        realEstateAsset.value.entryDateActivityLmnp,
        requirement === "same_year" ? "years" : "days"
      );
      switch (requirement) {
        case "same":
        case "same_year":
          return diff === 0;

        case "anterior":
          return diff < 0;
      }
    };
    const init = async () => {
      await productsStore.switchProduct({ id: props.productId });
      if (
        props.realEstateAssetId !== null &&
        props.realEstateAssetId !== "new"
      ) {
        realEstateAsset.value = cloneDeep(
          realEstateAssetsStore.getRealEstateAsset(props.realEstateAssetId) ||
            defaultRealEstate
        );
        if (!realEstateAsset.value.asbestos) {
          realEstateAsset.value.asbestos = reactive({
            asbestosStatus: "Non contrôlé",
          });
        }
        if (!realEstateAsset.value.dpe) {
          realEstateAsset.value.dpe = reactive({ dpeGrade: DPE.UNKNOWN });
        }
      } else {
        realEstateAsset.value = defaultRealEstate;
      }
      await initRentalUnit({
        realEstateAsset: realEstateAsset.value || {},
      });

      if (rentalUnit.value.rentalUsage) {
        const rentalUsageValue = accountingPeriodsStore.isLMNP
          ? rentalUsageLmnpList.find(
              (el) => el.value === rentalUnit.value.rentalUsage
            )
          : rentalUsageList.find(
              (el) => el.value === rentalUnit.value.rentalUsage
            );

        if (rentalUsageValue) {
          return (rentalUsage.value = rentalUsageValue);
        }
      }
    };

    watch(
      () => rentalUnit.value.taxTvaEnable,
      () => {
        if (rentalUnit.value.taxTvaEnable) {
          rentalUnit.value.crlEnable = false;
        }
        isCrlEnableEditable.value = !rentalUnit.value.taxTvaEnable;
      }
    );

    const adjustTaxRate = () => {
      if (accountingPeriodsStore.isLMNP) {
        if (rentalUsage.value && realEstateAsset.value.address.zip) {
          rentalUnit.value.taxRateTVA = tvaRentalUsageMapping(
            rentalUsage.value,
            realEstateAsset.value.address.zip
          );

          rentalUnit.value.taxTvaEnable = taxTvaEnableMapping(
            rentalUsage.value
          );
        }
      }
    };

    watch(
      [props],
      async () => {
        await init();
      },
      { deep: true }
    );

    onBeforeMount(async () => await init());

    return {
      TAX_DEDUCTIONS,
      PROPERTY_TYPES,
      PROPERTY_STATUS,
      realEstateAsset,
      onEditClick,
      cancelEdition,
      validateInProgress,
      validate,
      today,
      navigateToDoc,
      modalTitle,
      rentalUnit,
      rentalStateList,
      rentalUsageList: accountingPeriodsStore.isLMNP
        ? rentalUsageLmnpList
        : rentalUsageList,
      taxTvaRule,
      taxRateTvaList,
      TaxRateTva,
      anomalyOptions: (() => {
        const options = { ...anomalyOptions.value };
        if (props.realEstateAssetId) {
          options[AnomalyCode.asset] = { referenceId: props.realEstateAssetId };
        }
        return options;
      })(),
      TypeReference,
      accountingPeriodsStore,
      compareBoughtAndEntryActivity,
      fileAsbestos,
      fileImage,
      imageRealEstateAsset,
      formattedBoughtPrice,
      updateBoughtPrice,
      realEstateAssetDocumentsRules,
      asbestosDeclaration,
      statusAsbestosList,
      deleteDocuments,
      dpeLists,
      isCrlEnableEditable,
      DPE,
      dpeDiagnostic,
      fileDpe,
      deleteFileInProgress,
      subscription,
      PlanType: SubscriptionsModel.PlanType,
      adjustMarketPrice,
      searchItemsStreetList,
      searchItemsCityList,
      searchItemsZipList,
      searchAddress,
      updateAddress,
      rentalUsage,
      adjustTaxRate,
    };
  },
});
