var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margins flex-row",attrs:{"id":"test"}},[_c('b',[_vm._v("Somme de vos comptes ("+_vm._s(_vm.previousYear ? ("31/12/" + _vm.targetedYear) : _vm.targetedYear)+") ")]),(!_vm.isEditing && !_vm.isLoading)?_c('div',{attrs:{"id":"injectBalanceResult"}},[_vm._v(" "+_vm._s(_vm.$n(_vm.totalSum, "currency", "fr-FR"))+" ")]):_vm._e(),(!_vm.isEditing && _vm.isLoading)?_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}}):_vm._e(),(!_vm.subscriptionsStore.isFree && _vm.accountingPeriodsStore.isIR)?[(!_vm.isEditing)?_c('v-tooltip',{staticClass:"icon",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(
            _vm.targetedAccountingBalanceSheet &&
            _vm.targetedAccountingBalanceSheet.type === 'recovery' &&
            _vm.targetedAccountingBalanceSheet.isValidated === false
          )?_c('v-btn',_vm._g(_vm._b({staticClass:"raise",attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isEditing = !_vm.isEditing}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1):_vm._e()]}}],null,false,2570281235)}):[_c('v-text-field',{staticClass:"text-field",attrs:{"suffix":"€","hide-details":"auto","outlined":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.confirmUpdate($event)}},model:{value:(_vm.tempTotalSum),callback:function ($$v) {_vm.tempTotalSum=$$v},expression:"tempTotalSum"}}),_c('div',{staticClass:"flex-row gap-1 reduced-width"},[_c('v-btn',{staticClass:"position-left",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelUpdate}},[_c('v-icon',[_vm._v(" mdi-arrow-u-left-top ")])],1),_c('v-btn',{staticClass:"position-right",attrs:{"id":"injectBalanceBtn","color":"primary"},on:{"click":_vm.confirmUpdate}},[_c('v-icon',[_vm._v(" mdi-check ")])],1)],1)]]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }