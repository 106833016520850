var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',_vm._l((_vm.partnersList),function(partner){return _c('v-col',{key:partner.id,attrs:{"cols":_vm.$vuetify.breakpoint.xsOnly
        ? '12'
        : _vm.$vuetify.breakpoint.smOnly
        ? '4'
        : _vm.$vuetify.breakpoint.mdOnly
        ? '6'
        : _vm.$vuetify.breakpoint.lgOnly
        ? '3'
        : '2'}},[_c('v-card',{staticClass:"container",attrs:{"loading":_vm.isLoading,"outlined":""}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","height":"10","indeterminate":""}})],1),_c('div',{staticClass:"header-role",class:("" + (partner.role === 'owner'
            ? 'header-role-owner'
            : partner.role === 'grantee'
            ? 'header-role-grantee'
            : '')),attrs:{"id":"partner-role"}},[_vm._v(" "+_vm._s(partner.role === "owner" ? "Gérant" : partner.role === "grantee" ? "Associé" : "")+" ")]),_c('v-card-text',{staticClass:"section"},[_c('p',{staticClass:"mb-0",attrs:{"id":"partner-name"}},[(partner.type === _vm.PartnerTypeEnum.NATURAL_PERSON)?_c('b',[_vm._v(_vm._s(partner.firstName)+" "+_vm._s(partner.lastName))]):_vm._e(),(partner.type === _vm.PartnerTypeEnum.LEGAL_PERSON)?_c('b',[_vm._v(" "+_vm._s(partner.denomination || partner.siret))]):_vm._e()]),_c('p',[_vm._v(_vm._s(partner.email))]),(!_vm.isTaxRegimeIs && !_vm.isTaxRegimeLmnp)?_c('p',{staticClass:"mb-0"},[_vm._v(" Pleine Propriété : "),_c('b',[_vm._v(_vm._s(partner.partsPP))])]):_vm._e(),(!_vm.isTaxRegimeIs && !_vm.isTaxRegimeLmnp)?_c('p',{staticClass:"mb-0"},[_vm._v(" Usufruits : "),_c('b',[_vm._v(_vm._s(partner.partsUF))])]):_vm._e(),(!_vm.isTaxRegimeIs && !_vm.isTaxRegimeLmnp)?_c('p',{staticClass:"mb-0"},[_vm._v(" Nue Propriété : "),_c('b',[_vm._v(_vm._s(partner.partsNP))])]):_vm._e(),(!_vm.isTaxRegimeIs && !_vm.isTaxRegimeLmnp)?_c('p',{staticClass:"mb-0"},[_vm._v(" Fiscales : "),_c('b',[_vm._v(_vm._s(partner.partsPP + partner.partsUF))])]):_vm._e(),(_vm.isTaxRegimeIs || _vm.isTaxRegimeLmnp)?_c('p',{staticClass:"mb-0"},[_vm._v(" Parts Sociales : "),_c('b',[_vm._v(_vm._s(partner.partsSocial ? partner.partsSocial : 0))])]):_vm._e(),_c('p',[_vm._v(" Détention : "),_c('b',[_vm._v(_vm._s(partner.pctDetention ? partner.pctDetention : 0)+" %")])]),_c('p',[_vm._v(" Accès Ownily : "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[(partner.status === 'confirmed')?_c('span',[_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v("mdi-check")])],1):_vm._e(),(partner.status === 'pending')?_c('span',[_c('v-icon',{attrs:{"color":"primary lighten-1"}},[_vm._v("mdi-sync")])],1):_vm._e(),(partner.status === 'none')?_c('span',[_vm._v("-")]):_vm._e()])]}}],null,true)},[_vm._v(" "+_vm._s(partner.status)+" ")])],1),_c('div',{staticClass:"section-action"},[_c('v-btn',{staticClass:"btn-link",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('selectPartner', partner)}}},[_c('span',[_vm._v(" Voir le détail "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye-outline")])],1)])],1),_c('v-divider',{staticClass:"mb-2 mt-2"}),_vm._v(" Solde du compte courant au "+_vm._s(_vm.todayDate)+" : "),_c('b',[_vm._v(_vm._s(_vm.$n(_vm.getBalance(partner.id), "currency", "fr-FR")))]),_c('div',{staticClass:"section-action"},[_c('v-btn',{staticClass:"btn-link",attrs:{"text":""},on:{"click":function($event){return _vm.partnerIncomeDetails(partner.id)}}},[_c('span',[_vm._v(" Voir le détail "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye-outline")])],1)])],1)],1)],2)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }