var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"accountingOperations"}},[_c('PageHeader',{attrs:{"displayHelperWithId":_vm.ArticleEnum.ACCOUNTING_TRANSACTION_IS},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","depressed":""}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Export "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.reportings),function(reporting){return _c('v-list-item',{key:reporting.name,staticClass:"ma-2",attrs:{"link":""},on:{"click":function($event){return reporting.download()}}},[_c('v-list-item-title',[_vm._v(_vm._s(reporting.name))])],1)}),1)],1)]},proxy:true}])},[_vm._v(" Mon FEC ")]),_c('div',{staticClass:"section section-header"},[_c('router-link',{staticClass:"back d-flex align-center",attrs:{"to":{ name: _vm.ROUTE_NAMES.AccountingDocuments }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#000"}},[_vm._v("mdi-chevron-left")]),(_vm.taxRegime === _vm.TaxRegime.IR_2072)?[_vm._v(" Mon bilan simplifié ")]:_vm._e(),(
          _vm.taxRegime === _vm.TaxRegime.IS_2065 || _vm.taxRegime === _vm.TaxRegime.LMNP_2031
        )?[_vm._v(" Mes documents comptables ")]:_vm._e()],2)],1),_c('div',{staticClass:"section"},[_c('div',{staticClass:"section-body"},[_c('EditableTable',{ref:"editableTable",staticClass:"position-full",attrs:{"headers":_vm.headers,"items":_vm.operationItems,"viewType":"readOnly","itemsPerPage":-1,"groupBy":"date","hideGroupByCloseButton":true,"hideFooter":"","disableSort":""},scopedSlots:_vm._u([_vm._l((_vm.headers),function(ref){
        var headerValue = ref.value;
return {key:("item." + headerValue),fn:function(ref){
        var value = ref.value;
return [_c('div',{key:headerValue,staticClass:"d-flex"},[(headerValue === 'date')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(headerValue === 'account')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(headerValue === 'summary')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(headerValue === 'accountName')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(headerValue === 'attribute')?_c('div',[(value)?_c('v-chip',{staticClass:"chip",attrs:{"small":"","outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(value.icon)+" ")]),_c('span',_vm._g(_vm._b({staticClass:"textOverflow"},'span',attrs,false),on),[_vm._v(" "+_vm._s(value.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(value.name))])])],1):_vm._e()],1):_vm._e(),(headerValue === 'debitText')?_c('div',[_vm._v(" "+_vm._s(_vm.$n(value, "currency", "fr-FR"))+" ")]):_vm._e(),(headerValue === 'creditText')?_c('div',[_vm._v(" "+_vm._s(_vm.$n(value, "currency", "fr-FR"))+" ")]):_vm._e()])]}}})],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }