var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"taxDeclaration2033Lines"}},[_c('v-card',{staticClass:"px-4 py-3",attrs:{"outlined":""}},_vm._l((_vm.lines),function(line){return _c('div',{key:line.title,staticClass:"px-4 py-3 line",class:{ isTotal: line.isTotal, isResult: line.isResult }},[_c('div',{staticClass:"section-body"},[_c('div',{staticClass:"mr-8 line-title",attrs:{"id":("L" + (line.lineNumber))}},[_vm._v(" "+_vm._s(line.title)+" ")]),_c('div',{staticClass:"mr-8"},[_c('p',{staticClass:"mb-0 line-name"},[_vm._v(_vm._s(line.name))]),(line.description)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(line.description)+" ")]):_vm._e()]),(
            line.lineNumber ===
              _vm.TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L310 &&
            _vm.isEqualAccountingResult !== undefined &&
            _vm.showBadgeDev
          )?_c('div',{staticClass:"ml-auto line-amount"},[_c('BadgeDev',{attrs:{"text":("isEqualAccountingResult: " + _vm.isEqualAccountingResult)}})],1):_vm._e(),(
            (line.lineNumber ===
              _vm.TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L352 ||
              line.lineNumber ===
                _vm.TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L354) &&
            _vm.isEqualTaxResultBeforeAllocation !== undefined &&
            _vm.showBadgeDev
          )?_c('div',{staticClass:"ml-auto line-amount"},[_c('BadgeDev',{attrs:{"text":("isEqualTaxResultBeforeAllocation: " + _vm.isEqualTaxResultBeforeAllocation)}})],1):_vm._e(),(
            (line.lineNumber ===
              _vm.TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L370 ||
              line.lineNumber ===
                _vm.TaxDeclaration2033LineNumberSimplifiedIncomeStatement.L372) &&
            _vm.isEqualTaxResultAfterAllocation !== undefined &&
            _vm.showBadgeDev
          )?_c('div',{staticClass:"ml-auto line-amount"},[_c('BadgeDev',{attrs:{"text":("isEqualTaxResultAfterAllocation: " + _vm.isEqualTaxResultAfterAllocation)}})],1):_vm._e(),_c('div',{staticClass:"ml-auto mr-4 line-amount"},[(
              _vm.isLMNP && line.lineNumber === _vm.TaxDeclaration2033LineNumber.L352
            )?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Resultat comptable si bénéfice - amortissements reportables => dans la limite de 0€. "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.menu = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('p',[_vm._v(" L’avantage du LMNP est de pouvoir reporter les amortissements sur les années suivante en cas de bénéfice pour réduire le résultat comptable (bénéfice) à 0€ et ainsi ne pas être imposé sur cette activité. ")]),_c('p',{staticClass:"text-primary text-decoration-underline",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: _vm.ROUTE_NAMES.AccountingCarryForward })}}},[_vm._v(" Amortissements reportables n-1 = "),_c('b',[_vm._v(_vm._s(_vm.$n( _vm.previousYearCarryForwardDepreciation, "currency", "fr-FR" )))])])])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex align-end"},[(line.subscriptionPlanType === _vm.SubscriptionPlanType.Free)?_c('v-skeleton-loader',{staticClass:"mr-1",attrs:{"boilerplate":"","type":"text","min-width":"1.5rem"}}):_vm._e(),_vm._v(_vm._s(line.value)+" ")],1)],1),_c('div',[(line.transactionIds)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":line.transactionIds && !line.transactionIds.length,"icon":"","small":""},on:{"click":function($event){return _vm.goToTransactions(line)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"id":"productTour-eye","color":"#000","small":""}},[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Voir le détail des transactions ")])]):_vm._e()],1)])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }