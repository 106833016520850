
























import {
  defineComponent,
  computed,
  watch,
  ref,
  Ref,
  onMounted,
} from "@vue/composition-api";

import { realEstateAmortisationsStore } from "@/store";
import { useRealEstateAmortisation } from "@/components/core/realEstateAmortisation/realEstateAmortisation.usable";
import { RealEstateAmortisation } from "@edmp/api";

import Tag from "@/components/atom/Tag.vue";
import RealEstateAmortisationTable from "./RealEstateAmortisationTable.vue";
import i18n from "@/plugins/i18n";

export default defineComponent({
  name: "RealEstateAmortisations",
  components: { Tag, RealEstateAmortisationTable },
  props: {
    realEstateAssetId: { type: String, required: true },
  },
  setup(props) {
    const { totalAmortisationByRealEstateAssets, amortisationTypeText } =
      useRealEstateAmortisation();
    const realEstateAmortisationsList: Ref<
      (RealEstateAmortisation & {
        component: string;
        textValue: string;
      })[]
    > = ref([]);
    const getRealEstateAmortisationsList = () => {
      const amortisations =
        realEstateAmortisationsStore.getRealEstateAmortisationsByRealEstateAssetId(
          props.realEstateAssetId
        );

      const amortisationData: (RealEstateAmortisation & {
        component: string;
        textValue: string;
      })[] = [];

      for (const line of amortisations) {
        const component = amortisationTypeText[line.type];
        amortisationData.push({
          ...line,
          component,
          textValue: i18n.n(line.value, "currency", "fr-FR"),
        });
      }

      realEstateAmortisationsList.value = amortisationData;
    };
    const isNew = computed(
      () => realEstateAmortisationsList.value.length === 0
    );

    const headers = [
      { text: "Composant", value: "component" }, // component field is added in realEstateAmortisationsList in computed
      { text: "Valeur", value: "textValue" },
      { text: "Durée (an)", value: "durationInYear" },
      { text: "Taux (%)", value: "rate" },
    ];
    watch(
      realEstateAmortisationsStore.realEstateAmortisations,
      () => {
        getRealEstateAmortisationsList();
      },
      { deep: true }
    );
    onMounted(() => {
      getRealEstateAmortisationsList();
    });
    return {
      headers,
      realEstateAmortisationsList,
      isNew,
      totalAmortisation: computed(
        () =>
          totalAmortisationByRealEstateAssets.value[props.realEstateAssetId] ||
          0
      ),
    };
  },
});
