



































































































import {
  defineComponent,
  computed,
  Ref,
  ref,
  onBeforeMount,
} from "@vue/composition-api";
import {
  BalanceCategories,
  PartialField,
  TypeReference,
  TaxRegime,
} from "@edmp/api";
import { cloneDeep, flatMap, merge } from "lodash";

import {
  accountingPeriodsStore,
  transactionsStore,
  accountingsStore,
} from "@/store";
import { ROUTE_NAMES } from "@/router/routes";

import { useTransactions } from "@/components/core/transactions/transactions.usable";
import PageHeader from "@/components/atom/PageHeader.vue";
import AccountingPeriodChoice from "@/components/core/accounting-period/AccountingPeriodChoice.vue";
import EditableTable, {
  TableHeaderItems,
} from "@/components/atom/table/EditableTable.vue";
import {
  Direction,
  getGlobalAccountFromAccountWithReferenceCounter,
} from "@edmp/api";
import { accountingsService } from "@/services";
import i18n from "@/plugins/i18n";

export default defineComponent({
  name: "AccountingBalance",
  components: {
    PageHeader,
    AccountingPeriodChoice,
    EditableTable,
  },

  setup() {
    const taxRegime = computed(
      () => accountingPeriodsStore.currentAccountingPeriod?.taxRegime
    );
    const balanceCategories: Ref<BalanceCategories | undefined> = ref();

    const categories = computed(() =>
      flatMap(transactionsStore.categoriesList)
    );
    const { getAttribute } = useTransactions();

    const headers: TableHeaderItems = [
      { text: "COMPTE", value: "account" },
      { text: "INTITULÉ DU COMPTE", value: "accountName" },
      { text: "ATTRIBUT", value: "attributeName" },
      { text: "DÉBIT", value: "debitText" },
      { text: "CRÉDIT", value: "creditText" },
      { text: "SOLDE", value: "balanceText" },
    ];

    const balanceCategoriesItems = computed(() => {
      const balanceCategoriesItemsOut = balanceCategories.value
        ? cloneDeep(balanceCategories.value)?.categories.map(
            (
              category: PartialField<
                BalanceCategories["categories"][number],
                "yearly"
              >
            ) => {
              delete category.yearly;
              return merge(category, {
                accountName: categories.value.find(
                  (categorie) =>
                    categorie.number ===
                    (category.reference
                      ? getGlobalAccountFromAccountWithReferenceCounter(
                          category.account,
                          category.reference
                        )
                      : category.account)
                )?.name,
                attributeName: category.reference
                  ? {
                      name: getAttribute(
                        category.reference.type,
                        category.reference.referredId
                      ),
                      iconName:
                        category.reference.type === TypeReference.bankAccount
                          ? "mdi-bank-outline"
                          : category.reference.type ===
                            TypeReference.realEstateAsset
                          ? "mdi-home-outline"
                          : category.reference.type ===
                              TypeReference.rentalAgreement ||
                            category.reference.type === TypeReference.tenant
                          ? "mdi-account-key-outline"
                          : category.reference.type === TypeReference.partner
                          ? "mdi-account-outline"
                          : category.reference.type ===
                            TypeReference.realEstateLoan
                          ? "mdi-home-percent-outline"
                          : category.reference.type ===
                            TypeReference.supportingDocument
                          ? "mdi-folder-outline"
                          : undefined,
                    }
                  : undefined,

                debitText: i18n.n(category.debit, "currency", "fr-FR"),
                creditText: i18n.n(category.credit, "currency", "fr-FR"),
                balanceText:
                  category.balanceDirection === "debit"
                    ? `-${i18n.n(category.balance, "currency", "fr-FR")}`
                    : i18n.n(category.balance, "currency", "fr-FR"),
              });
            }
          )
        : [];
      if (balanceCategoriesItemsOut.length) {
        balanceCategoriesItemsOut.push({
          types: [],
          account: "",
          accountName: "",
          debit: 0,
          credit: 0,
          balance: 0,
          balanceDirection: Direction.credit,
          debitText: `TOTAL DES SOLDES DÉBITEUR : ${i18n.n(
            balanceCategories.value?.debit ?? 0,
            "currency",
            "fr-FR"
          )}`,
          creditText: `TOTAL DES SOLDES CRÉDITEUR : ${i18n.n(
            balanceCategories.value?.credit ?? 0,
            "currency",
            "fr-FR"
          )}`,
          balanceText:
            balanceCategories.value?.balanceDirection === "debit"
              ? `TOTAL DÉBIT CRÉDIT : -${i18n.n(
                  balanceCategories.value?.balance ?? 0,
                  "currency",
                  "fr-FR"
                )}`
              : `TOTAL DÉBIT CRÉDIT : ${i18n.n(
                  balanceCategories.value?.balance ?? 0,
                  "currency",
                  "fr-FR"
                )}`,
          attributeName: undefined,
          transactionIds: [],
        });
      }
      return balanceCategoriesItemsOut;
    });

    const downloadBalanceReport = () =>
      accountingsService.getReportingSimple({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });

    const downloadAccountingReport = () =>
      accountingsService.getReportingComplete({
        accountingPeriodId: accountingPeriodsStore.currentId,
      });

    onBeforeMount(async () => {
      balanceCategories.value = await accountingsStore.getBalanceCategories(
        accountingPeriodsStore.currentId
      );
      await transactionsStore.updateCategoriesList();
    });

    return {
      headers,
      balanceCategoriesItems,
      ROUTE_NAMES,
      taxRegime,
      TaxRegime,
      downloadBalanceReport,
      downloadAccountingReport,
    };
  },
});
