var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EditableTable',{ref:"editableTable",attrs:{"headers":_vm.headers,"items":_vm.documents,"viewType":"editable","itemsPerPage":-1,"hideFooter":"","addItemText":"Ajouter un justificatif","isEnableEditItem":false,"isEnableValidateItem":false},on:{"addItem":_vm.addItem,"deleteItem":_vm.deleteItem},scopedSlots:_vm._u([_vm._l((_vm.headers),function(ref){
var headerValue = ref.value;
return {key:("item." + headerValue),fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{key:headerValue,staticClass:"d-flex"},[(headerValue === 'new')?_c('div',[(item.new)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("New")]):_vm._e()],1):_vm._e(),(headerValue === 'metadata.status')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(headerValue === 'metadata.issuanceDate')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(headerValue === 'metadata.wording')?_c('div',[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),(headerValue === 'metadata.amountTotal')?_c('div',[_vm._v(" "+_vm._s(_vm.$n(value, "currency", "fr-FR"))+" ")]):_vm._e(),(headerValue === 'metadata.amount')?_c('div',[_vm._v(" "+_vm._s(_vm.$n(value, "currency", "fr-FR"))+" ")]):_vm._e(),(headerValue === 'metadata.amountTax')?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$n(value || 0, "currency", "fr-FR")))])]):_vm._e(),(headerValue === 'type')?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"supporting-document-type pa-3",attrs:{"depressed":"","color":"#757575","href":item.href,"target":"_blank"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.extension(value))+" ")])]}}],null,true)},[_c('span',[_vm._v("Voir le document")])])],1):_vm._e()])]}}})],null,true)}),_c('Confirm',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }