var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.viewData)?_c('div',{staticClass:"accounting-view"},[_c('div',{staticClass:"accounting-view-header"},[_c('div',{staticClass:"accounting-view-header-title"},[_c('TitleNew',{attrs:{"id":"productTour-accountingTitle","hLevel":"h1"}},[_vm._v("Ma comptabilité au "+_vm._s(_vm.lastPeriodDate))]),_c('v-spacer')],1),_c('div',{staticClass:"filter-container d-flex justify-center"},[_c('div',{staticClass:"filter-buttons mr-4 mb-2"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"switch-button px-6 py-2",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('h5',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.viewSelectedText)+" ")]),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,488956868)},[_c('v-list',[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"primary"},model:{value:(_vm.viewProductSelected),callback:function ($$v) {_vm.viewProductSelected=$$v},expression:"viewProductSelected"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Total SCI")])],1)]},proxy:true}],null,false,4054220614)}),(_vm.viewData.length > 0)?_c('hr'):_vm._e(),_c('v-list-item-group',{model:{value:(_vm.viewRealEstateAssetsSelected),callback:function ($$v) {_vm.viewRealEstateAssetsSelected=$$v},expression:"viewRealEstateAssetsSelected"}},_vm._l((_vm.viewData),function(realEstateAssetItem,index){return _c('v-list-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(realEstateAssetItem.name))])],1)]}}],null,true)})}),1)],1)],1)],1),_c('div',{staticClass:"filter-buttons mr-4 mx-2 mb-2"},[_c('AccountingPeriodChoice')],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"icon":"","color":"#000"}},[_c('v-icon',[_vm._v("mdi-help-circle-outline")])],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"productTour-exportReporting","color":"primary","depressed":""}},on),[_c('v-icon',[_vm._v("mdi-upload")]),_vm._v(" Export "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,3280516126)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',{staticClass:"ma-2",attrs:{"id":"track-export-balance-report","link":""},on:{"click":function($event){return _vm.downloadBalanceReport()}}},[_c('v-list-item-title',[_vm._v("Balance à date")])],1),_c('v-list-item',{staticClass:"ma-2",attrs:{"id":"track-export-accounting-report","link":""},on:{"click":function($event){return _vm.downloadAccountingReport()}}},[_c('v-list-item-title',[_vm._v("Écritures comptables")])],1),(
            _vm.taxRegime === _vm.TaxRegime.IS_2065 ||
            _vm.taxRegime === _vm.TaxRegime.LMNP_2031
          )?_c('v-list-item',{staticClass:"ma-2",attrs:{"id":"track-export-accounting-report","link":""},on:{"click":function($event){return _vm.downloadFullAccountingReport()}}},[_c('v-list-item-title',[_vm._v("FEC")])],1):_vm._e(),(
            _vm.taxRegime === _vm.TaxRegime.IS_2065 ||
            _vm.taxRegime === _vm.TaxRegime.LMNP_2031
          )?_c('v-list-item',{staticClass:"ma-2",attrs:{"id":"track-export-accounting-report","link":""},on:{"click":function($event){return _vm.downloadFullStandardAccountingReport()}}},[_c('v-list-item-title',[_vm._v("FEC normé")])],1):_vm._e()],1)],1)],1),(_vm.taskGroup && _vm.taskGroup.status !== _vm.TaskStatus.COMPLETED)?_vm._l((_vm.taskGroup.tasksLocal),function(taskLocal){return _c('TaskCard',{key:taskLocal.code,attrs:{"taskLocal":taskLocal}})}):_vm._e(),_c('v-row',{staticClass:"accounting-details-section gap no-gutters pt-4"},[_c('v-col',{staticClass:"accounting-summary pa-0 mt-2"},[_c('div',{staticClass:"card"},[(_vm.balance)?_c('Balance',{attrs:{"balance":_vm.balance}}):_vm._e()],1)]),_c('v-col',{staticClass:"accounting-summary pa-0 mt-2"},[_c('div',{staticClass:"card"},[_c('TitleNew',{staticClass:"my-3 mt-md-0 mb-md-8 px-5 pt-5",attrs:{"hLevel":"h2"}},[_vm._v("Détail des recettes et dépenses")]),_c('div',{staticClass:"row-x2 px-5 pb-5"},[_c('div',{staticClass:"btn-recette position-left"},[_c('v-btn',{staticClass:"ma-1 custom-transform-class text-none",attrs:{"color":_vm.selectedDetail === 'recettes' ? 'primary' : 'white',"rounded":"","depressed":"","id":"track-income_graph"},on:{"click":function($event){_vm.selectedDetail = 'recettes'}}},[_vm._v(" Recettes ")]),_c('v-btn',{staticClass:"ma-1 custom-transform-class text-none",attrs:{"color":_vm.selectedDetail === 'depenses' ? 'primary' : 'white',"rounded":"","depressed":"","id":"track-expense_graph"},on:{"click":function($event){_vm.selectedDetail = 'depenses'}}},[_vm._v(" Dépenses ")])],1),(Object.keys(_vm.annualsCategories).length > 0)?_c('BalanceDetailsChart',{staticClass:"mx-auto position-right",staticStyle:{"max-width":"200px"},attrs:{"categories":_vm.annualsCategories,"baseColor":_vm.selectedDetail === 'depenses'
                ? ['#1C75C9', '#A3649A']
                : ['#DFEEFF', '#008F83']}}):_vm._e()],1),(_vm.accountingPeriodsStore.isIR && _vm.subscriptionsStore.isPremium)?_c('div',{staticClass:"px-5 pb-5"},[_vm._v(" Votre résultat comptable : "+_vm._s(_vm.$n(_vm.accountingResult, "currency-no-decimal", "fr-FR"))+" ")]):_vm._e()],1)]),_c('v-col',{staticClass:"accounting-summary balance-partner-accounts pa-0 mt-2"},[_c('div',{staticClass:"card"},[_c('TitleNew',{staticClass:"my-3 mt-md-0 mb-md-8 px-5 pt-5",attrs:{"hLevel":"h2"}},[_vm._v("Suivi des comptes courants d'associé")]),_vm._l((_vm.partnersList),function(partner){return _c('div',{key:partner.id,staticClass:"balance-partner-accounts-items px-5 pb-3"},[_c('div',{staticClass:"header-role",class:partner.role === 'owner'
                ? 'header-role-owner'
                : partner.role === 'grantee'
                ? 'header-role-grantee'
                : ''},[_vm._v(" "+_vm._s(partner.role === "owner" ? "Gérant" : partner.role === "grantee" ? "Associé" : "")+" ")]),_c('b',{staticClass:"partner-identity"},[_vm._v(_vm._s(_vm.getPartnerName(partner.id)))]),_vm._v(" Solde au "+_vm._s(_vm.lastPeriodDate)+" : "),_c('b',[_vm._v(" "+_vm._s(_vm.$n(_vm.getBalancePartner(partner.id), "currency", "fr-FR")))])])})],2)])],1),_c('AccountingFinance',{attrs:{"transactions":_vm.transactionsFiltered}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }